import React, { useState } from "react";
import { Card, Label, Icon, List, Popup, Button } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import moment from "moment";

import { MomentAuth0Factory } from "../helpers/timestamps";
import { resetPassword } from "../services/auth";

const UserCard = (props) => {
  // console.log("user: ", JSON.stringify(props.user, null, 3));
  const [resettingPassword, setResettingPassword] = useState(false);

  const {
    id,
    name,
    email,
    email_verified,
    company,
    admin,
    created_at,
    last_login,
    approvals,
    approved_until,
  } = props.user;

  const { linkToUser, history, onDelete, onStartSupport } = props;

  const linkToUserClick = linkToUser
    ? () => history.replace(`/users/${id}`)
    : null;

  const safeName = name.length > 0 ? name : email;

  const safeCompany = company.length > 0 ? company : "Unknown Company";

  const createdMoment = MomentAuth0Factory(created_at);
  const lastLoginMoment = MomentAuth0Factory(last_login);
  let approvedUntilMoment = null;
  if (approved_until) {
    approvedUntilMoment = moment(approved_until * 1000);
  }

  let differenceInLoginTimestamp = false;
  if (createdMoment && lastLoginMoment) {
    const duration = moment.duration(lastLoginMoment.diff(createdMoment));
    differenceInLoginTimestamp = duration.minutes() > 1;
  }

  const approvalElements = approvals.map((approval) => {
    const {
      approved_by_email,
      approved_by_name,
      approved_on,
      productName,
      bySelf,
    } = approval;

    let safeApprovedBy = null;
    if (approved_on && approved_by_email && approved_by_name) {
      const emailLink = (
        <a href={`mailto:${approved_by_email}`}>{approved_by_email}</a>
      );
      if (approved_by_email === approved_by_name) {
        safeApprovedBy = <span>{emailLink}</span>;
      } else {
        safeApprovedBy = (
          <span>
            {approved_by_name} ({emailLink})
          </span>
        );
      }
    }

    const approvedOnMoment = MomentAuth0Factory(approved_on);

    let approvedText = <span>Approved manually</span>;
    if (approvedOnMoment != null) {
      approvedText = bySelf ? (
        <span>Approved automatically {approvedOnMoment.fromNow()}</span>
      ) : (
        <span>
          Approved {approvedOnMoment.fromNow()} by {safeApprovedBy}
        </span>
      );
    }

    return (
      <div key={approved_on} style={{ marginBottom: "0.25em" }}>
        <Label
          basic
          horizontal
          size="large"
          color="green"
          style={{ marginRight: "0.5em" }}
        >
          {productName}
        </Label>
        <Popup
          flowing
          on={["hover", "click"]}
          position="right center"
          trigger={approvedText}
        >
          {approvedOnMoment && approvedOnMoment.format("LLL")}
          {!approvedOnMoment && <span>Unknown</span>}
        </Popup>
      </div>
    );
  });

  return (
    <Fade>
      <Card onClick={linkToUserClick} fluid style={{ marginBottom: "0.75em" }}>
        <Card.Content>
          <Card.Header>
            <Icon
              style={{ paddingRight: "0.5em" }}
              color={admin ? "yellow" : "grey"}
              name={admin ? "star" : "user"}
            />
            <a href={`mailto: ${email}`} style={{ color: "black" }}>
              {safeName}
              {safeName !== email ? ` - ${email}` : ""}
            </a>
            {email_verified && (
              <Popup
                flowing
                trigger={
                  <Icon
                    color="green"
                    name="check circle"
                    style={{ paddingLeft: "0.5em", opacity: 0.4 }}
                  />
                }
              >
                Verified
              </Popup>
            )}

            <div style={{ float: "right" }}>
              <Popup
                hoverable
                trigger={
                  <Button
                    icon="eye"
                    disabled={onStartSupport == null}
                    circular
                    basic
                    onClick={() => {
                      let meetingUrl = window.prompt(
                        "Enter the meeting URL or ID. Do not reuse meeting IDs or passcodes."
                      );
                      const meetingUrlParts = meetingUrl.split("?");

                      if (meetingUrlParts.length > 0) {
                        const urlPath = meetingUrlParts[0].split("/");
                        const meetingId = urlPath[urlPath.length - 1]; // https://us02web.zoom.us/j/MEETINGID?pwd=HASH
                        const startSupportVars = { userId: id, meetingId };
                        // console.log(`startSupportVars:${JSON.stringify(startSupportVars, null, 3)}`);
                        onStartSupport(startSupportVars);
                      }
                    }}
                  />
                }
              >
                Start Remote Support
              </Popup>
            </div>

            {onDelete != null && (
              <div style={{ float: "right" }}>
                <Popup
                  hoverable
                  trigger={<Button icon="delete" circular basic />}
                >
                  <Button
                    color="red"
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    Click to Delete
                  </Button>
                </Popup>
              </div>
            )}

            <div style={{ float: "right" }}>
              <Popup
                hoverable
                trigger={
                  <Button
                    icon="setting"
                    circular
                    basic
                    loading={resettingPassword}
                  />
                }
              >
                <Button
                  disabled={resettingPassword}
                  color="green"
                  onClick={() => {
                    setResettingPassword(true);
                    resetPassword(email)
                      .then(() => {
                        alert(`Password reset email sent to ${email}.`);
                      })
                      .finally(() => {
                        setResettingPassword(false);
                      });
                  }}
                >
                  Send Password Reset Email
                </Button>
              </Popup>
            </div>
          </Card.Header>

          <Card.Meta style={{ paddingLeft: "1.75em" }}>{safeCompany}</Card.Meta>
          <Card.Description>
            <List divided>
              <List.Item>
                <List.Header>Joined</List.Header>
                <List.Description>
                  <Popup
                    flowing
                    on={["hover", "click"]}
                    position="right center"
                    trigger={<span>{createdMoment.fromNow()}</span>}
                  >
                    {createdMoment.format("LLL")}
                  </Popup>
                </List.Description>
              </List.Item>

              {differenceInLoginTimestamp && (
                <List.Item>
                  <List.Header>Last Login</List.Header>
                  <List.Description>
                    <Popup
                      flowing
                      on={["hover", "click"]}
                      position="right center"
                      trigger={<span>{lastLoginMoment.fromNow()}</span>}
                    >
                      {lastLoginMoment.format("LLL")}
                    </Popup>
                  </List.Description>
                </List.Item>
              )}

              {approvedUntilMoment && (
                <List.Item>
                  <List.Header>Expires</List.Header>
                  <List.Description>
                    <Popup
                      flowing
                      on={["hover", "click"]}
                      position="right center"
                      trigger={<span>{approvedUntilMoment.fromNow()}</span>}
                    >
                      {approvedUntilMoment.format("LLL")}
                    </Popup>
                  </List.Description>
                </List.Item>
              )}

              {admin && (
                <List.Item>
                  <List.Header>Admin</List.Header>
                </List.Item>
              )}
            </List>
            {approvalElements}
          </Card.Description>
        </Card.Content>
        {props.children && (
          <Card.Content extra style={{ paddingTop: "1em" }}>
            {props.children}
          </Card.Content>
        )}
      </Card>
    </Fade>
  );
};

export default UserCard;
