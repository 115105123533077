import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Query } from "react-apollo";

import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Label,
  Button,
  Header
} from "semantic-ui-react";

import { GET_USER } from "../queries/users";
import UserCard from "../components/UserCard";

class User extends Component {
  render = () => {
    const { userId } = this.props.match.params;

    return (
      <Query query={GET_USER} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          const user = data && data.user;
          // console.log("user: " + JSON.stringify(user));

          return (
            <Container style={{ marginTop: 20 }}>
              <Dimmer active={loading}>
                <Loader content={"Loading"} />
              </Dimmer>

              <Button
                onClick={() => this.props.history.goBack()}
                content="Back"
              />

              {error && <Label color="red" content="Error" size="large" />}

              {user == null && !loading && <Header>User Not Found</Header>}
              {user != null && (
                <Segment>
                  <UserCard user={user} />
                </Segment>
              )}
            </Container>
          );
        }}
      </Query>
    );
  };
}

const mapStateToProps = state => {
  return {};
};

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(User);
