import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import CopyToClipboard from "react-copy-to-clipboard";
import { withToastManager } from "react-toast-notifications";

import {
  Container,
  Dimmer,
  Loader,
  Label,
  Button,
  Header,
  Icon,
  Popup,
} from "semantic-ui-react";

import {
  GET_PRODUCT_WITH_REQUESTS,
  GET_PRODUCT_RECENT_REQUESTS,
  APPROVE_PRODUCT_REQUEST,
  APPROVE_TEMPORARY_PRODUCT_REQUEST,
} from "../queries/products";
import UserCard from "../components/UserCard";
import { DELETE_USER } from "../queries/users";

const LOAD_CHUNK = 25;

const TEMP_ACCESS_DAY = 60 * 60 * 24;
const TEMP_ACCESS_WEEK = TEMP_ACCESS_DAY * 7;

class Requests extends Component {
  renderProductRequests = (options) => {
    const {
      product,
      loading,
      fetchMore,
      queryVariables,
      recent,
      toastManager,
    } = options;
    const requests = product.requests || product.recentRequests;
    const { totalCount } = requests;
    let total = (requests && requests.users.length) || 0;

    let emails = [];
    if (requests && requests.users.length) {
      emails = requests.users.map((u) => u.email).join(",\n");
    }

    const innerTotal =
      total === totalCount ? `${total}` : `${total} of ${totalCount}`;

    const emailPopup = (
      <Popup
        hoverable
        on="hover"
        position="right center"
        trigger={<span>({innerTotal})</span>}
      >
        <CopyToClipboard text={emails}>
          <Button
            primary
            basic
            onClick={() => {
              toastManager.add(`Copied ${total} email addresses.`, {
                appearance: "success",
                autoDismiss: true,
                pauseOnHover: false,
              });
            }}
          >
            Copy Email Addresses
          </Button>
        </CopyToClipboard>
      </Popup>
    );

    return (
      <div key={product.id} style={{ marginTop: "1em" }}>
        <Header style={{ marginBottom: 0 }}>
          {product.id} -{recent ? " Recent " : " All "}Requests {emailPopup}
        </Header>
        <Header size="small" style={{ opacity: 0.4, marginTop: 0 }}>
          Sorted {recent ? "Newest First" : "Oldest First"}
        </Header>
        {requests.users
          .filter((user) => !user.pending_approval)
          .map((user) =>
            this.renderApprovalCard(product, user, queryVariables)
          )}

        <div>
          {!loading && totalCount !== 0 && total < totalCount && (
            <Button
              onClick={() => {
                fetchMore({
                  variables: {
                    offset: total,
                  },
                  updateQuery: (prev, { fetchMoreResult, variables }) => {
                    // console.log(
                    //   "variables: " + JSON.stringify(variables, null, 3)
                    // );
                    // console.log("prev: " + JSON.stringify(prev, null, 3));
                    // console.log(
                    //   "fetchMoreResult: " +
                    //     JSON.stringify(fetchMoreResult, null, 3)
                    // );

                    if (!fetchMoreResult) return prev;
                    const allRequestsUsers = [
                      ...prev.product.requests.users,
                      ...fetchMoreResult.product.requests.users,
                    ];

                    let newResult = Object.assign({}, prev);
                    newResult.product.requests.users = allRequestsUsers;
                    return newResult;
                  },
                });
              }}
            >
              Load {Math.min(LOAD_CHUNK, totalCount - total)} More
            </Button>
          )}
        </div>
        <Loader active={loading} inline style={{ margin: "1.5em 0em" }} />
      </div>
    );
  };

  renderApprovalCard = (product, user, variables) => {
    const recent = variables.last != null;

    const query = recent
      ? GET_PRODUCT_RECENT_REQUESTS
      : GET_PRODUCT_WITH_REQUESTS;

    const refetchQueries = [{ query, variables }];
    return (
      <Mutation
        key={user.id}
        mutation={DELETE_USER}
        refetchQueries={refetchQueries}
        notifyOnNetworkStatusChange
      >
        {(deleteUser, deleteUserProps) => {
          return (
            <UserCard
              key={user.id}
              user={user}
              history={this.props.history}
              onDelete={(userId) => {
                deleteUser({ variables: { userId } });
              }}
            >
              <Mutation
                mutation={APPROVE_PRODUCT_REQUEST}
                refetchQueries={refetchQueries}
                notifyOnNetworkStatusChange
              >
                {(approve, { data, loading, error }) => (
                  <React.Fragment>
                    <Dimmer active={loading}>
                      <Loader content="Approving..." />
                    </Dimmer>
                    <Dimmer active={deleteUserProps.loading}>
                      <Loader content="Deleting..." />
                    </Dimmer>
                    {error && <Label color="red">Error</Label>}
                    <Button
                      color="green"
                      onClick={() =>
                        approve({
                          variables: { productId: product.id, userId: user.id },
                          optimisticResponse: {
                            __typename: "Mutation",
                            approve: {
                              id: user.id,
                              __typename: "User",
                              pending_approval: true,
                            },
                          },
                        })
                      }
                    >
                      Approve S3C
                    </Button>
                  </React.Fragment>
                )}
              </Mutation>
              <Popup
                on="click"
                content={
                  <div>
                    <Mutation
                      mutation={APPROVE_TEMPORARY_PRODUCT_REQUEST}
                      refetchQueries={refetchQueries}
                      notifyOnNetworkStatusChange
                    >
                      {(approveTemp, { data, loading, error }) => (
                        <React.Fragment>
                          {error && <Label color="red">Error</Label>}
                          <Button
                            style={{ marginBottom: 8 }}
                            fluid
                            color="green"
                            onClick={() =>
                              approveTemp({
                                variables: {
                                  productId: product.id,
                                  userId: user.id,
                                  secondsInFuture: TEMP_ACCESS_DAY,
                                },
                              })
                            }
                          >
                            Approve for 24 Hours
                          </Button>
                        </React.Fragment>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={APPROVE_TEMPORARY_PRODUCT_REQUEST}
                      refetchQueries={refetchQueries}
                      notifyOnNetworkStatusChange
                    >
                      {(approveTemp, { data, loading, error }) => (
                        <React.Fragment>
                          {error && <Label color="red">Error</Label>}
                          <Button
                            fluid
                            color="green"
                            onClick={() =>
                              approveTemp({
                                variables: {
                                  productId: product.id,
                                  userId: user.id,
                                  secondsInFuture: TEMP_ACCESS_WEEK,
                                },
                              })
                            }
                          >
                            Approve for 7 Days
                          </Button>
                        </React.Fragment>
                      )}
                    </Mutation>
                  </div>
                }
                trigger={<Button>Temporary</Button>}
              />
            </UserCard>
          );
        }}
      </Mutation>
    );
  };

  render = () => {
    const { recent, toastManager } = this.props;
    const variables = Object.assign(
      { id: "S3C" },
      recent ? { last: LOAD_CHUNK } : { first: LOAD_CHUNK, offset: 0 }
    );

    return (
      <Query
        query={recent ? GET_PRODUCT_RECENT_REQUESTS : GET_PRODUCT_WITH_REQUESTS}
        variables={variables}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, refetch, fetchMore, variables }) => {
          let requests =
            data &&
            data.product &&
            (data.product.requests || data.product.recentRequests);
          let total = (requests && requests.totalCount) || 0;

          const users = requests && requests.users;

          return (
            <Container>
              <Dimmer active={loading && total === 0}>
                <Loader
                  content={
                    recent ? "Loading Recent Requests" : "Loading All Requests"
                  }
                />
              </Dimmer>
              {error && (
                <Label color="red" content={error.message} size="large" />
              )}
              {total === 0 && !loading && (
                <Header>
                  <Icon name="check circle" size="massive" color="green" /> No
                  Requests
                </Header>
              )}
              {users &&
                this.renderProductRequests({
                  recent,
                  product: data.product,
                  loading,
                  fetchMore,
                  queryVariables: variables,
                  toastManager,
                })}
              {!loading && (
                <Button
                  style={{ margin: "2em 0em" }}
                  onClick={() => {
                    refetch();
                  }}
                  icon="refresh"
                  content="Reload"
                />
              )}
            </Container>
          );
        }}
      </Query>
    );
  };
}

export default withToastManager(Requests);
